<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#707487;"
      d="M185.379,423.724h-13.473l-39.492-150.069H70.621l40.674,154.562
	c2.041,7.756,9.054,13.162,17.074,13.162h57.011c4.875,0,8.828-3.953,8.828-8.828C194.207,427.677,190.254,423.724,185.379,423.724z
	"
    />
    <path
      style="fill:#5B5D6E;"
      d="M137.778,428.217l-29.964-113.863c-1.67-6.344,3.921-12.197,10.349-10.894
	c7.972,1.617,15.809,3.306,23.434,5.091l-9.183-34.897H70.621l40.674,154.562c2.041,7.756,9.054,13.162,17.074,13.162h26.483
	C146.831,441.379,139.819,435.974,137.778,428.217z"
    />
    <path
      style="fill:#7F8499;"
      d="M17.655,176.552C7.905,176.552,0,184.456,0,194.207v52.966c0,9.75,7.905,17.655,17.655,17.655h35.31
	v-88.276H17.655z"
    />
    <path
      style="fill:#707487;"
      d="M35.31,220.69c-9.75,0-17.655-7.905-17.655-17.655v-26.483C7.905,176.552,0,184.456,0,194.207v52.966
	c0,9.75,7.905,17.655,17.655,17.655h35.31V220.69H35.31z"
    />
    <path
      style="fill:#FFDC64;"
      d="M361.931,70.621h-26.483c-9.75,0-17.655,7.904-17.655,17.655v4.137
	c-29.796,13.558-132.161,56.846-258.127,73.427c-8.845,1.164-15.528,8.591-15.528,17.514v74.674c0,8.922,6.682,16.349,15.528,17.514
	c125.966,16.579,228.331,59.869,258.127,73.426v4.137c0,9.75,7.905,17.655,17.655,17.655h26.483c9.75,0,17.655-7.905,17.655-17.655
	V88.276C379.586,78.525,371.681,70.621,361.931,70.621z"
    />
    <path
      style="fill:#FFF082;"
      d="M503.172,229.517h-88.276c-4.879,0-8.828-3.948-8.828-8.828s3.948-8.828,8.828-8.828h88.276
	c4.879,0,8.828,3.948,8.828,8.828S508.052,229.517,503.172,229.517z"
    />
    <g>
      <path
        style="fill:#FFF6B4;"
        d="M467.862,145.026c-3.423,0-6.681-2.001-8.113-5.354c-1.922-4.474,0.156-9.664,4.638-11.586
		l8.828-3.785c4.448-1.949,9.664,0.138,11.586,4.638c1.922,4.474-0.156,9.664-4.638,11.586l-8.828,3.785
		C470.207,144.802,469.026,145.026,467.862,145.026z"
      />
      <path
        style="fill:#FFF6B4;"
        d="M414.897,167.724c-3.423,0-6.681-1.999-8.113-5.354c-1.922-4.474,0.156-9.664,4.638-11.586
		l26.483-11.353c4.439-1.949,9.664,0.147,11.586,4.638c1.922,4.474-0.156,9.664-4.638,11.586l-26.483,11.353
		C417.241,167.5,416.061,167.724,414.897,167.724z"
      />
      <path
        style="fill:#FFF6B4;"
        d="M476.69,317.793c-1.164,0-2.345-0.224-3.474-0.715l-8.853-3.794
		c-4.483-1.913-6.56-7.104-4.638-11.586c1.913-4.491,7.147-6.578,11.586-4.638l8.853,3.794c4.483,1.913,6.56,7.104,4.638,11.586
		C483.371,315.794,480.113,317.793,476.69,317.793z"
      />
      <path
        style="fill:#FFF6B4;"
        d="M441.37,302.655c-1.164,0-2.345-0.224-3.474-0.715l-26.474-11.345
		c-4.483-1.922-6.56-7.112-4.638-11.586c1.913-4.491,7.138-6.578,11.586-4.638l26.474,11.345c4.483,1.922,6.56,7.112,4.638,11.586
		C448.052,300.655,444.793,302.655,441.37,302.655z"
      />
    </g>
    <path
      style="fill:#FFC850;"
      d="M308.279,344.755c3.685,1.599,6.933,3.039,9.514,4.213V92.413c-4.34,1.975-10.388,4.636-17.655,7.697
	v133.946c0,10.707-9.41,18.962-20.017,17.494c-47.513-6.573-127.477-16.01-220.456-20.329c-8.845-0.412-15.528-3.032-15.528-6.182
	v32.986c0,8.919,6.674,16.348,15.517,17.512c107.699,14.174,197.938,47.801,240.512,65.743
	C301.247,341.737,306.727,344.078,308.279,344.755z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
